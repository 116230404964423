import * as React from "react"

const SvgSource = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 88.55 46.1" {...props}>
    <defs>
      <clipPath id="source_svg__a">
        <path
          style={{
            fill: "none",
          }}
          d="M0 0h88.55v46.1H0z"
        />
      </clipPath>
    </defs>
    <g
      style={{
        clipPath: "url(#source_svg__a)",
      }}
    >
      <path
        d="M88.55 43.9h-9V23.21h7.76v-1.76h-7.76V2.16h8.47V.35H77.3v45.4h11.25V43.9Zm-15.66.09h-5.87c-2.97 0-4.9-1.46-5.78-4.37-.82-2.65-1.24-8.18-1.24-16.59s.41-13.81 1.24-16.46c.91-2.91 2.84-4.37 5.78-4.37h5.74L72.72.39h-5.69c-3.97 0-6.56 1.59-7.76 4.76-1.09 2.85-1.63 8.81-1.63 17.87s.54 15.15 1.63 18c.65 1.74 1.6 2.97 2.87 3.71 1.18.71 2.81 1.06 4.9 1.06h5.65l.22-1.81ZM42.98 24.14V2.07h3.57c1.29 0 2.21.09 2.74.26.68.24 1.16.71 1.46 1.41.44 1.12.66 4.53.66 10.24 0 4.85-.22 7.75-.66 8.69-.27.65-.74 1.06-1.41 1.24-.5.15-1.37.22-2.6.22h-3.75Zm11.21 21.62-5.91-19.9c2.5-.06 4.03-.76 4.59-2.12.53-1.18.8-4.43.8-9.75 0-6.53-.34-10.43-1.01-11.69-.41-.85-1.1-1.41-2.07-1.68-.74-.18-2.04-.26-3.93-.26h-5.91v45.39H43V25.9h2.74l5.87 19.85h2.6ZM23.83 27.97c0 8.68.31 13.87.93 15.57.38 1.09 1.03 1.81 1.94 2.16.71.26 1.93.4 3.66.4s2.96-.13 3.66-.4c.91-.35 1.56-1.07 1.94-2.16.62-1.7.93-6.9.93-15.57V.53c.06 0 .06-.03 0-.09.03-.06.03-.09 0-.09h-2.21v27.27c0 7.85-.21 12.54-.62 14.07-.26 1-.71 1.66-1.32 1.99-.44.24-1.24.35-2.38.35s-1.94-.12-2.38-.35c-.62-.32-1.06-.99-1.32-1.99-.41-1.53-.62-6.22-.62-14.07L26.09.35h-2.25v27.62ZM0 23.03c0 10.03.57 16.38 1.72 19.06.68 1.56 1.69 2.63 3.04 3.22 1.15.53 2.88.79 5.21.79s4.03-.26 5.21-.79c1.35-.59 2.37-1.66 3.04-3.22 1.15-2.68 1.72-9.03 1.72-19.06s-.63-15.75-1.9-18.66c-.7-1.62-1.72-2.76-3.04-3.44C13.79.31 12.12 0 9.97 0S6.15.31 4.94.93C3.62 1.61 2.6 2.75 1.9 4.37.63 7.28 0 13.5 0 23.03m9.97 21.22c-1.77 0-3.09-.23-3.97-.71-1-.56-1.76-1.54-2.29-2.96-.88-2.47-1.32-8.32-1.32-17.56s.48-14.5 1.46-17.21c.53-1.5 1.29-2.56 2.29-3.18.91-.56 2.19-.84 3.84-.84s2.93.28 3.84.84c.97.62 1.73 1.68 2.29 3.18.97 2.68 1.46 8.41 1.46 17.21 0 9.23-.44 15.09-1.32 17.56-.53 1.41-1.31 2.4-2.34 2.96-.88.47-2.19.71-3.93.71"
        style={{
          fill: "#7b868c",
        }}
      />
    </g>
  </svg>
)

export default SvgSource
