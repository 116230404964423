import * as React from "react"

const SvgSmile = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76.16 52.01" {...props}>
    <defs>
      <clipPath id="smile_svg__a">
        <path
          style={{
            fill: "none",
          }}
          d="M0 0h76.16v52.01H0z"
        />
      </clipPath>
    </defs>
    <g
      style={{
        clipPath: "url(#smile_svg__a)",
      }}
    >
      <path
        d="M69.76 52.01c3.12-2.62 4.97-5.22 5.56-7.81.56-2.56.84-9.03.84-19.41 0-9.24-.29-15.01-.88-17.34-.56-2.35-2.4-4.84-5.51-7.46l-1.15 1.28c2.71 2.26 4.31 4.47 4.81 6.62.5 2.18.75 7.81.75 16.9 0 10.21-.26 16.53-.79 18.97-.47 2.35-2.06 4.66-4.76 6.93l1.15 1.32Zm-3.75-10.04h2.21v-2.21h-2.21v2.21Zm0-29.07h2.21v-2.21h-2.21v2.21ZM54.23 45.62h-9V24.93h7.76v-1.76h-7.76V3.88h8.47V2.07H42.98v45.4h11.25v-1.85Zm-15.18 0h-7.23V2.07h-2.25v45.4h9.48v-1.85ZM25.37 2.07h-2.25v45.4h2.25V2.07Zm-6.58 45.4V2.07h-3.53L9.39 40.98 3.4 2.07H0v45.4h2.16V7.5l6.22 39.97h1.99l6.26-40.59v40.59h2.16Z"
        style={{
          fill: "#7b868c",
        }}
      />
    </g>
  </svg>
)

export default SvgSmile
