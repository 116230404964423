import * as React from "react"

const SvgSprout = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 89.68 46.1" {...props}>
    <defs>
      <clipPath id="sprout_svg__a">
        <path
          style={{
            fill: "none",
          }}
          d="M0 0h89.68v46.1H0z"
        />
      </clipPath>
    </defs>
    <g
      style={{
        clipPath: "url(#sprout_svg__a)",
      }}
    >
      <path
        d="M82.75 45.75V2.16h6.93V.35H73.62v1.81h6.93v43.59h2.21ZM56.72 27.97c0 8.68.31 13.87.93 15.57.38 1.09 1.03 1.81 1.94 2.16.71.27 1.93.4 3.66.4s2.96-.13 3.66-.4c.91-.35 1.56-1.07 1.94-2.16.62-1.7.93-6.9.93-15.57V.53c.06 0 .06-.03 0-.09.03-.06.03-.09 0-.09h-2.21v27.27c0 7.85-.21 12.54-.62 14.07-.26 1-.71 1.66-1.32 1.99-.44.24-1.24.35-2.38.35s-1.94-.12-2.38-.35c-.62-.32-1.06-.99-1.32-1.99-.41-1.53-.62-6.22-.62-14.07L58.97.35h-2.25v27.62Zm-23.83-4.94c0 10.03.57 16.38 1.72 19.06.68 1.56 1.69 2.63 3.04 3.22 1.15.53 2.88.79 5.21.79s4.03-.26 5.21-.79c1.35-.59 2.37-1.66 3.04-3.22 1.15-2.68 1.72-9.03 1.72-19.06s-.63-15.75-1.9-18.66c-.71-1.62-1.72-2.76-3.04-3.44C46.68.31 45.01 0 42.86 0s-3.82.31-5.03.93c-1.32.68-2.34 1.82-3.04 3.44-1.26 2.91-1.9 9.13-1.9 18.66m9.97 21.22c-1.76 0-3.09-.23-3.97-.71-1-.56-1.76-1.54-2.29-2.96-.88-2.47-1.32-8.32-1.32-17.56s.49-14.5 1.46-17.21c.53-1.5 1.29-2.56 2.29-3.18.91-.56 2.19-.84 3.84-.84s2.93.28 3.84.84c.97.62 1.74 1.68 2.3 3.18.97 2.68 1.45 8.41 1.45 17.21 0 9.23-.44 15.09-1.32 17.56-.53 1.41-1.31 2.4-2.34 2.96-.88.47-2.19.71-3.93.71M19.15 24.13V2.07h3.57c1.29 0 2.21.09 2.74.26.68.24 1.16.71 1.46 1.41.44 1.12.66 4.53.66 10.24 0 4.85-.22 7.75-.66 8.69-.26.65-.74 1.06-1.41 1.24-.5.15-1.37.22-2.6.22h-3.75Zm11.2 21.62-5.91-19.9c2.5-.06 4.03-.76 4.59-2.12.53-1.18.79-4.43.79-9.75 0-6.53-.34-10.43-1.02-11.69-.41-.85-1.1-1.41-2.07-1.68-.74-.18-2.05-.26-3.93-.26h-5.91v45.4h2.25V25.9h2.74l5.87 19.85h2.6ZM2.25 25.37V2.07h3.57c1.29 0 2.21.09 2.74.26.68.24 1.16.71 1.46 1.41.44 1.12.66 4.53.66 10.24s-.22 8.69-.66 9.75c-.26.7-.74 1.18-1.41 1.41-.5.15-1.37.22-2.6.22H2.25Zm0 20.38V27.09h3.66c1.88 0 3.19-.09 3.93-.26.97-.26 1.66-.79 2.07-1.59.68-1.24 1.01-4.99 1.01-11.25S12.58 3.56 11.91 2.3C11.5 1.45 10.81.89 9.84.62 9.11.44 7.8.36 5.91.36H0v45.39h2.25Z"
        style={{
          fill: "#7b868c",
        }}
      />
    </g>
  </svg>
)

export default SvgSprout
